import React from 'react';
import ReactDom from 'react-dom';
import { Button, Box } from 'grommet';

const functionURL = process.env.FUNCTION_URI
  ? process.env.FUNCTION_URI + `/api/SendMail`
  : `/api/SendMail`;

class Confirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      errorMessage: null,
      message: props.values,
      isConfirmationVisible: true,
    };
    this.handleSendMail = (flag) => {
      props.setShowModal(flag);
    };
    this.handleShowMessageBox = (flag) => {
      props.setShowMessage(flag);
    };
  }
  handleCloseModal = () => {
    this.handleSendMail(false);
  };
  closeModal = (e) => {
    e.preventDefault();
    this.handleSendMail(false);
  };

  onClick = async (event) => {
    event.preventDefault();
    this.setState({ submitting: true });
    const {
      contract,
      purpose,
      application,
      companyName,
      prefecture,
      city,
      branch,
      userName,
      fromEmail,
      body,
    } = this.state.message;

    const response = await fetch(functionURL, {
      method: 'post',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: new URLSearchParams({
        contract,
        purpose,
        application,
        companyName,
        prefecture,
        city,
        branch,
        userName,
        fromEmail,
        body,
      }).toString(),
    });
    if (response.status === 200) {
      this.handleShowMessageBox(true);
      // const dialogElement = document.querySelector('dialog');
      // dialogElement.showModal();
    } else {
      const json = await response.json();
      this.setState({
        error: json.error,
        submitting: false,
      });
    }
  };

  render() {
    return ReactDom.createPortal(
      <Box>
        {this.state.isConfirmationVisible ? (
          <Box className="container" onClick={this.closeModal}>
            <Box className="modal" pad="medium">
              <h2>ご入力内容のご確認</h2>
              <button className="close" onClick={this.handleCloseModal}>
                X
              </button>
              <div>{this.state.errorMessage}</div>
              <Box pad="medium" fill="horizontal">
                <form method="post" action={functionURL}>
                  <table
                    order="1"
                    rules="all"
                    className="tbl-fix"
                    style={{ marginTop: '5px', borderColor: '#dddddd' }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            width: '40%',
                            display: 'none',
                          }}
                        ></th>
                        <th
                          style={{
                            width: '60%',
                            display: 'none',
                          }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr-left">
                        <td>
                          <Box>お客様のご利用状況</Box>
                        </td>
                        <td>
                          <Box>{this.state.message?.contract}</Box>
                        </td>
                      </tr>
                      <tr className="tr-left">
                        <td>
                          <Box>ご用件</Box>
                        </td>
                        <td>
                          <Box>{this.state.message?.purpose}</Box>
                        </td>
                      </tr>
                      <tr className="tr-left">
                        <td>
                          <Box>対象アプリ</Box>
                        </td>
                        <td>
                          <Box>{this.state.message?.application}</Box>
                        </td>
                      </tr>
                      <tr className="tr-left">
                        <td>
                          <Box>会社名または団体名</Box>
                        </td>
                        <td>
                          <Box>{this.state.message?.companyName}</Box>
                        </td>
                      </tr>
                      <tr className="tr-left">
                        <td>
                          <Box>都道府県</Box>
                        </td>
                        <td>
                          <Box>{this.state.message?.prefecture}</Box>
                        </td>
                      </tr>
                      <tr className="tr-left">
                        <td>
                          <Box>市区町村以下</Box>
                        </td>
                        <td>
                          <Box>{this.state.message?.city}</Box>
                        </td>
                      </tr>
                      <tr className="tr-left">
                        <td>
                          <Box>所属部署</Box>
                        </td>
                        <td>
                          <Box>{this.state.message?.branch}</Box>
                        </td>
                      </tr>
                      <tr className="tr-left">
                        <td>
                          <Box>お名前</Box>
                        </td>
                        <td>
                          <Box>{this.state.message?.userName}</Box>
                        </td>
                      </tr>
                      <tr className="tr-left">
                        <td>
                          <Box>メールアドレス</Box>
                        </td>
                        <td>
                          <Box>{this.state.message?.fromEmail}</Box>
                        </td>
                      </tr>
                      <tr className="tr-left">
                        <td>
                          <Box>お問い合わせ内容</Box>
                        </td>
                        <td>{this.state.message?.body}</td>
                      </tr>
                    </tbody>
                  </table>
                  <Box align="center" style={{ marginTop: '20px' }}>
                    <Button
                      style={{
                        marginTop: `7px`,
                        boxSizing: 'border-box',
                        border: '2px solid #7D4CDB',
                        borderRadius: '18px',
                        padding: '4px 22px',
                        fontSize: '18px',
                        lineHeight: '24px',
                      }}
                      type="submit"
                      disabled={this.state.submitting}
                      onClick={this.onClick}
                    >
                      送信
                    </Button>
                  </Box>
                </form>
                <div>{this.state.errorMessage}</div>
              </Box>
            </Box>
          </Box>
        ) : (
          ''
        )}
      </Box>,
      typeof window !== `undefined` ? document.getElementById('portal') : null
    );
  }
}

export default Confirm;
