import React from 'react';
import ReactDom from 'react-dom';
import { Box, Button } from 'grommet';

class MessageBox extends React.Component {
  render() {
    return ReactDom.createPortal(
      <Box className="container">
        <Box
          className="modal"
          pad="medium"
          style={{
            zIndex: 1000,
            border: '3px',
            backgroundColor: '#ffffff',
            borderStyle: 'dotted',
            borderColor: '#7D4CDB',
            borderRadius: '30px',
            height: 'auto',
            width: 'auto',
          }}
        >
          <Box align="center" style={{ marginBottom: '20px' }}>
            <span id="dialogLabel">お問い合わせを送信しました。</span>
          </Box>
          <Box align="center">
            <a href="/">
              <Button id="close" type="button" label="閉じる"></Button>
            </a>
          </Box>
        </Box>
      </Box>,
      typeof window !== `undefined` ? document.getElementById('portal') : null
    );
  }
}
export default MessageBox;
