import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Confirm from '../components/contact/confirm';
import MessageBox from '../components/util/messageBox';
import Layout from '../components/layout/layout';
import Seo from '../components/seo';
import { Box, Form, Button, ResponsiveContext } from 'grommet';

const Contact = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm();
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const FromEmail = watch('fromEmail');

  const onSubmitData = () => {
    handleSubmit(setIsConfirmationVisible(true));
  };
  let appDirection = 'column';
  let pageMaxWidth = '80%';

  return (
    <Layout>
      <ResponsiveContext.Consumer>
        {(size) => {
          if (size !== 'xsmall' && size !== 'small') {
            appDirection = 'row';
            pageMaxWidth = '80%';
          } else {
            appDirection = 'column';
            pageMaxWidth = '100%';
          }
          return (
            <>
              <Seo title="お問い合わせ" />
              <Box
                style={{
                  maxWidth: pageMaxWidth,
                  margin: '0 auto',
                }}
              >
                <Box style={{ marginBottom: '2rem' }}>
                  <p>
                    <span>■よくあるお問い合わせはこちらをご確認ください。</span>
                  </p>
                  <Box direction={appDirection} style={{ paddingLeft: '20px' }}>
                    <Box style={{ flexBasis: '25%' }}>
                      <a href="/qa/FORKLORE">FORKLORE全般</a>
                    </Box>
                    <Box style={{ flexBasis: '25%' }}>
                      <a href="/qa/driverecorderConnect">ドラレコConnect</a>
                    </Box>
                    <Box style={{ flexBasis: '25%' }}>
                      <a href="/qa/batteryConnect">バッテリーConnect</a>
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginBottom: '2rem' }}>
                  <p>
                    <span>■お申し込みの流れはこちらをご確認ください。</span>
                  </p>
                  <Box direction={appDirection} style={{ paddingLeft: '20px' }}>
                    <Box style={{ flexBasis: '50%' }}>
                      <a href="/howtoApply/driverecorderConnect">ドラレコConnect</a>
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginBottom: '1.5rem' }}>
                  <p style={{ marginBottom: '0px' }}>
                    ■ご利用申し込み、ご不明点、その他お問い合わせは以下のフォームにてご連絡ください。
                  </p>
                  <span style={{ color: 'red', marginLeft: '1rem' }}>*は必須項目です。</span>
                </Box>
                <div justify="center">
                  <Form
                    style={{
                      maxWidth: `850px`,
                      margin: '0 auto',
                    }}
                    className="FormModule"
                    method="post"
                    onSubmit={handleSubmit(onSubmitData)}
                  >
                    <Box direction={appDirection} style={{ marginBottom: '15px' }}>
                      <Box pad="small" style={{ flexBasis: '33%', marginBottom: '15px' }}>
                        <label htmlFor={'contract'}>お客様のご利用状況</label>
                        <select
                          id="contract"
                          name={'contract'}
                          {...register('contract', { required: true })}
                        >
                          <option value="新規のお客様">新規のお客様</option>
                          <option value="ご利用中のお客様">ご利用中のお客様</option>
                        </select>
                        {errors.contract && (
                          <span className="formError">お客様のご利用状況を選択してください</span>
                        )}
                      </Box>
                      <Box pad="small" style={{ flexBasis: '33%', marginBottom: '15px' }}>
                        <label htmlFor={'purpose'}>ご用件</label>
                        <select
                          id="purpose"
                          name={'purpose'}
                          {...register('purpose', { required: true })}
                        >
                          <option value="ご利用申し込みについて">ご利用申し込みについて</option>
                          <option value="サービスに関する質問">サービスに関する質問</option>
                          <option value="お困りの点について">お困りの点について</option>
                          <option value="その他">その他</option>
                        </select>
                        {errors.purpose && (
                          <span className="formError">ご用件を選択してください</span>
                        )}
                      </Box>
                      <Box pad="small" style={{ flexBasis: '33%', marginBottom: '15px' }}>
                        <label htmlFor={'application'}>対象アプリ</label>
                        <select
                          id="application"
                          name={'application'}
                          {...register('application', { required: true })}
                        >
                          <option value="ドラレコConnect">ドラレコConnect</option>
                          <option value="バッテリーConnect">バッテリーConnect</option>
                          <option value="その他全般">その他全般</option>
                        </select>
                        {errors.application && (
                          <span className="formError">対象アプリを選択してください</span>
                        )}
                      </Box>
                    </Box>
                    <Box direction="column" style={{ marginBottom: '15px' }}>
                      <label htmlFor="companyName">
                        会社名または団体名<span className={`requiredIcon`}></span>
                      </label>
                      <input id="companyName" {...register('companyName', { required: true })} />
                      {errors.companyName && (
                        <span className="formError">会社名または団体名を入力してください</span>
                      )}
                    </Box>
                    <Box direction="column" style={{ marginBottom: '15px' }}>
                      <label htmlFor="prefecture">都道府県</label>
                      <input id="prefecture" {...register('prefecture', { required: false })} />
                    </Box>
                    <Box direction="column" style={{ marginBottom: '15px' }}>
                      <label htmlFor="city">市区町村以下</label>
                      <input id="city" {...register('city', { required: false })} />
                    </Box>
                    <Box direction="column" style={{ marginBottom: '15px' }}>
                      <label htmlFor="branch">所属部署</label>
                      <input id="branch" {...register('branch', { required: false })} />
                    </Box>
                    <Box direction="column" style={{ marginBottom: '15px' }}>
                      <label htmlFor="userName">
                        お名前
                        <span className={`requiredIcon`}></span>
                      </label>
                      <input id="userName" {...register('userName', { required: true })} />
                      {errors.userName && (
                        <span className="formError">お名前を入力してください</span>
                      )}
                    </Box>
                    <Box direction="column" style={{ marginBottom: '15px' }}>
                      <label htmlFor="fromEmail">
                        メールアドレス
                        <span className={`requiredIcon`}></span>
                      </label>
                      <input
                        id="fromEmail"
                        {...register('fromEmail', {
                          required: true,
                          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i,
                        })}
                        type="email"
                      />
                      {errors.fromEmail && (
                        <span className="formError">メールアドレスを正しく入力してください</span>
                      )}
                    </Box>
                    <Box direction="column" style={{ marginBottom: '15px' }}>
                      <label htmlFor="fromEmailConfirm">
                        メールアドレス確認用
                        <span className={`requiredIcon`}></span>
                      </label>
                      <input
                        id="fromEmailConfirm"
                        {...register('fromEmailConfirm', {
                          required: true,
                          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i,
                          validate: {
                            message: (v) =>
                              v === FromEmail ? null : '同じメールアドレスを入力してください',
                          },
                        })}
                        type="email"
                      />
                      {errors.fromEmailConfirm && (
                        <span className="formError">同じメールアドレスを入力してください</span>
                      )}
                    </Box>
                    <Box direction="column" style={{ marginBottom: '15px', width: '100%' }}>
                      <label htmlFor="body" style={{ marginTop: '20px' }}>
                        お問い合わせ内容
                        <span className={`requiredIcon`}></span>
                      </label>
                      <textarea id="body" {...register('body', { required: true })} />
                      {errors.body && (
                        <span className="formError">お問い合わせ内容を入力してください</span>
                      )}
                    </Box>

                    <Box style={{ marginTop: '40px' }}>
                      <Box align="center">
                        <p>特記事項</p>
                        <p>次の内容をご確認のうえ、入力確認画面ボタンを押してください。</p>
                      </Box>
                      <Box>
                        <ol>
                          <li>
                            <span>個人情報の取り扱いについて</span>
                            <span>
                              ご記入いただきました、お客様の個人情報は、お問合せいただいた回答または連絡のみに利用します。
                            </span>
                            <span>当社が定めた個人情報保護方針に基づき、適切に管理します。</span>
                          </li>
                          <li>
                            <span>お問合せ対応について</span>
                            <span>
                              お寄せいただいた「お問合せ」につきましては、確認回答は当社営業日、営業時間中にいたしますので、お時間をいただく場合もございますこと、ご了承ください。
                            </span>
                          </li>
                        </ol>
                      </Box>
                    </Box>
                    <div id="root"></div>
                    <div id="portal"></div>
                    <Box align="center" style={{ marginTop: '20px' }}>
                      <Button label="入力内容を確認" type="submit" />
                    </Box>
                  </Form>
                  {isConfirmationVisible && (
                    <Confirm
                      values={getValues()}
                      setShowModal={setIsConfirmationVisible}
                      setShowMessage={setIsMessageVisible}
                    />
                  )}
                </div>
                {isMessageVisible && <MessageBox />}
              </Box>
            </>
          );
        }}
      </ResponsiveContext.Consumer>
    </Layout>
  );
};
export default Contact;
